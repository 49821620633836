<template>
	<div>
		<el-dialog :title="QWER('提问')" :visible.sync="dialogVisible" :show-close="true" :close-on-click-modal='false'
			width="900px" style="border-radius: 20px;margin-top: -8vh">
			<div>
				<el-form label-position="top" >
					<div class="flex-col" style="align-items: flex-start;">
						<el-form-item :label="QWER('标题')" prop="entity_name">
							<el-input v-model="queryForm.title" :placeholder="QWER('请输入')" style="width: 700px;"></el-input>
						</el-form-item>
						<el-form-item :label="QWER('内容')" prop="entity_name">
							<el-input v-model="queryForm.contents" :autosize="{ minRows: 5, maxRows: 20}" type="textarea"
								:placeholder="QWER('请输入')" style="width: 700px;"></el-input>
						</el-form-item>
						<el-form-item :label="QWER('图片')" prop="entity_name">
							<el-upload ref='uploadImgs' :action="baseUrl+'public/uploadImg'" list-type="picture-card" accept='image'
								:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success='onSuccess'>
								<i class="el-icon-plus"></i>
							</el-upload>
							
						</el-form-item>
					</div>
				</el-form>
				<div style="margin-top: 30px;">
					<el-button type='info' @click="close">{{QWER('关闭')}}</el-button>
					<el-button  @click="resetData">{{QWER('重置')}}</el-button>
					<el-button type="warning" @click="submintDate">{{QWER('提交')}}</el-button>
				</div>
			</div>
			
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible1">
		  <img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
	
</template>

<script>
	import {
		leaveMsgs
	} from "@/api/loans/loans"
	import {
	  actionUrl
	} from "@/utils/actionUrl";
	export default {
		data() {
			return {
				dialogVisible: false,
				dialogVisible1:false,
				dialogImageUrl:'',
				queryForm: {
					title: '',
					contents: '',
					annexs: '',
					sub_datas: '',
          parent_id: 0
				},
				imgs:[],
				baseUrl:actionUrl,
			};
		},
		created() {
			console.log("22",this.baseUrl)
		},
		methods: {
			QWER(message) {
				let temp = this.$t(message)
				return temp
			},
			close(){
				this.dialogVisible=false
			},
			open() {
				setTimeout(()=>{
					  this.resetData()
					},300)
						this.dialogVisible = true
					},
					resetData(){
						this.queryForm.title = ''
						this.queryForm.contents= ''
						this.queryForm.annexs = ''
						this.queryForm.sub_datas =''
				this.imgs = []
				this.$refs.uploadImgs.clearFiles()
			},
			submintDate(){
				let temp = []
				this.imgs.map(res=>{
					temp.push(this.getFileUrl(res))
				})
				this.queryForm.annexs = temp.join(',')
				if(!this.queryForm.title){
					this.$message({
						type:'error',
						message:this.QWER('请输入标题')
					})
					return 
				}
				if(!this.queryForm.contents){
					this.$message({
						type:'error',
						message:this.QWER('请输入内容')
					})
					return 
				}
				
				const load = this.$loading()
				this.queryForm.sub_datas = localStorage.getItem("mainData")
				leaveMsgs(this.queryForm).then(res=>{
          load.close()
          if(res.code==200){
            this.$emit('success')
            this.$message({
              type:'success',
              message:this.QWER('提交成功')
            })
            this.closeAdd()
          }else{
            this.$message({
              type:'error',
              message:res.msg
            })
          }


				})
			},
			closeAdd(){
				this.resetData()
				this.dialogVisible =false
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.imgs = fileList
			},
			onSuccess(response, file, fileList){
				this.imgs = fileList
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = this.getFileUrl(file);
				this.dialogVisible1 = true;
			},
			getFileUrl(file){
				return file.response.data.path
			}

		},
	};
</script>

<style lang="scss" scoped>

	::v-deep .el-form-item {
		margin-bottom: 8px;
	}
	::v-deep .el-form--label-top .el-form-item__label{
		padding-bottom: 0px;
		line-height: 30px;
	}
</style>