<template>
	<div class="body">
		<Nav></Nav>
		<!-- 表单 -->
		<div class="login">
			<div class="heart" style="margin-top: 50px;">
				<h2 class="title">{{QWER('登录')}}</h2>
				<el-form label-position="top" ref="sign" :model="sign" :rules="signRuler" label-width="80px">
					<!-- 账号密码 -->
					<div class="login-form">
						<el-form-item label="E-mail" prop="email">
							<el-input :placeholder="QWER('请输入邮箱地址')" v-model="sign.email"></el-input>
						</el-form-item>
						<el-form-item label="Code" prop="code">
							<div class="int-code">
								<el-input :placeholder="QWER('验证码')" v-model="sign.code" type="number" maxlength="6"
									oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
								<div class="get-code">
									<el-button type="info" size="mini" @click="sendCode"
										:disabled="sendTime ? true : false">{{QWER('发送验证码')}}<span v-show="sendTime">{{
                        `(${sendTime})`
                      }}</span></el-button>
								</div>
							</div>
						</el-form-item>
					</div>
				</el-form>
				<!-- 操作按钮 -->
				<div class="operate" style="margin-top: 150px;text-align: center;display: flex;flex-direction: column;align-items: center;">
					<!-- 登录 -->
					<el-button style="width: 400px;" type="info" @click="login">{{QWER('登录')}}</el-button>
					<el-button style="width: 400px;margin-top: 20px;margin-left: 0px;" @click="home()">{{QWER('去评估')}}</el-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Nav from "@/components/Nav/Nav.vue";
	import {
		userLogin,
		smsCode,
	} from "@/api/login/login.js";
	import {
		setToken,
		setUserInfo
	} from "@/utils/myAuth";
	export default {
		components: {
			Nav,
		},
		data() {
			var emailFun = (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.QWER('邮箱地址不能为空')));
				}
				setTimeout(() => {
					let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
					if (!reg.test(value)) {
						callback(new Error(this.QWER('请输入正确的邮箱地址')));
					} else {
						callback();
					}
				}, 1000);
			};
			return {
				signRuler: {
					email: [{
							required: true,
							message: this.QWER("请输入邮箱地址"),
							trigger: "blur",
						},
						{
							validator: emailFun,
							trigger: 'blur'
						}
					],
					code: {
						required: true,
						message: this.QWER("请输入邮箱验证码"),
						trigger: "blur",
					},
				},
				// 用户名密码
				sign: {
					email: "",
					code: ""
				},

				userInfo: {},
				sendTime: 0,
				sendTimer: "", // 验证码计时器
				sendResetTime: 0,
				sendResetTimer: "",
				codeImg: "",
				loading: false,
				refresh: true,
				load: false,
				checkKey: ''
			};
		},
		created() {
			this.init();
      console.log('接收参数',this.$route.query.path)
		},
		methods: {
			QWER(message,obj1) {
				let temp =  this.$t(message)
				if(obj1){
					temp = temp.replace('xxxxxx',obj1)
				}
			   return temp
			},
			async init(load) {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			},
			home(){
				this.$router.push("/");
			},
			// 登录
			async login() {
				this.$refs.sign.validate(async (valid) => {
					if (valid) {
						const data = {
							email: this.sign.email,
							code: this.sign.code
						};
						let loading=this.$loading()
						const res = await userLogin(data);
						loading.close()
						if (res.code == 200) {
							this.$message({
								type:'success',
								message:this.QWER('登录成功')
							});
							setToken(res.data.access_token);
							setUserInfo(JSON.stringify(res.data.user_info));
							this.$router.push(this.$route.query.path);
						} else {
							this.$message({
								type:'error',
								message:res.msg
							});
							return false;
						}
					}
				});
			},
			// 发送验证码
			async sendCode(name) {
				
				if (this.sendTime) {
					return
				}
				
				let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
				if (!reg.test(this.sign.email)) {
					this.$msg("Please enter a valid E-mail", "info");
					return;
				}
				let loading=this.$loading()
				const code = await smsCode({
					email: this.sign.email
				});
				loading.close()
				if (code.code == 200) {
					this.$message({
						type: "success",
						message: code.data
					})
					this.sendTime = 60;
					this.sendTimer = setInterval(() => {
						this.sendTime--;
						if (this.sendTime == 0) {
							this.sendTime = 0;
							clearInterval(this.sendTimer);
						}
					}, 1000);
				} else {
					this.$message({
						type: "error",
						message: code.msg
					})
				}
			},
		},


	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";

	.body {
		.login {
			color: rgb(146, 148, 149);

			.title {
				width: 400px;
				margin: 0 auto;
				font-size: 32px;
				font-weight: 650;
				color: #000;
				margin-bottom: 20px;
			}

			.login-form {
				.login-code {
					width: 400px;
					display: flex;
					justify-content: space-between;

					img {
						width: 100px;
						height: 40px;
					}

					::v-deep .el-input {
						width: 200px;
					}
				}
			}

			.sign-name {
				display: flex;
				align-items: center;
			}

			.retrieve {
				justify-content: flex-end;
				display: flex;
				// margin-bottom: 20px;
				width: 400px;
				margin: 0 auto 20px;
				cursor: pointer;
				//   align-items: flex-end;
			}

			.desc {
				width: 400px;
				margin: 0 auto;
				margin-bottom: 20px;
			}

			.el-col {
				.el-input {
					width: 200px;
				}
			}

			.el-input {
				width: 400px;
			}

			.el-form-item {
				@include column;
				// width: 400px;
				align-items: center;

				.el-form-item__content {
					position: relative;

					.int-code {
						width: 400px;

						.el-input {
							width: 250px;
						}
					}

					.get-code {
						position: absolute;
						right: 10px;
						top: 0;
					}
				}
			}

			::v-deep .el-form--label-top .el-form-item__label {
				width: 400px;
			}
		}
	}

	::v-deep .popper__arrow {
		display: none !important;
	}

	::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: #fff !important;
		color: $color;
	}

	::v-deep .el-form-item__label {
		color: rgb(146, 148, 149);
	}

	::v-deep .el-form-item__label:before {
		display: none;
		color: rgb(146, 148, 149) !important;
	}

	::v-deep .el-input__inner:focus {
		border-color: #333;
	}
</style>